//require('./bootstrap');

var $ = require('jquery');

window.$ = window.jQuery = $;

window.bootstrap = require('bootstrap5/dist/js/bootstrap.min');

// cambiar los estilos de la barra de navegacion en las otras paginas que no son la principal
if(null !== document.querySelector('.header-spacer')){
    menuItems=document.querySelectorAll('nav ul li a');
    for (let i = 0; i < menuItems.length; i++) {
        menuItems[i].classList.add('window-scroll');
    }
    document.querySelector('.logo-body').classList.add('window-scroll');
}

window.addEventListener('scroll', ()=>{
  if(screen.availWidth>=1200){
    document.querySelector('.site-header').classList.toggle('window-scroll', window.scrollY > 10);
  }
})

//abrir y cerrar menu
const navResponsive = () =>{
    document.querySelector('.close-menu-icon').classList.toggle('open');
    document.querySelector('.open-menu-icon').classList.toggle('open');
    document.querySelector('nav ul').classList.toggle('open');
    document.querySelector('#main-container').classList.toggle('open');
    document.querySelector('.site-header').classList.toggle('open');
    document.querySelector('.logo img').classList.toggle('open');
}
document.querySelector('.button-nav').addEventListener('click',navResponsive);


// imagen portada parallax
let portadaParallax = document.querySelector('.fachada-home');

function scrollPortada(){
  let scrollTop = this.pageYOffset;
  if(portadaParallax == null){
    return;
  }else{
    portadaParallax.style.transform = 'translateY(' + scrollTop * 0.04 + 'px)';
  }
}

window.addEventListener('scroll', scrollPortada);

// imagen portada parallax
let letrasPortada = document.querySelector('#parallax-letra');

function scrollParallax(){
  let scrollTop = this.pageYOffset;
  if(letrasPortada == null){
    return;
  }else{
    letrasPortada.style.transform = 'translate(-50%,' + scrollTop * 0.1 + 'px)';
  }
}

window.addEventListener('scroll', scrollParallax);